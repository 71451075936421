import { CommonModule, DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { AuthService, authInterceptor, errorsInterceptor } from '@mca/auth/api';
import { LocalStorageService, StorageService } from '@mca/shared/domain';
import { SharedFeatureGridModule } from '@mca/shared/feature-grid';
import { SharedFeatureSmartUiModule } from '@mca/shared/feature-smart-ui';
import { environmentToken } from '@mca/shared/util';
import { serviceWorkerClientUrlToken, ServiceWorkerCommand } from '@mca/shared/util/service-worker';
import { WebsocketBridge, WebsocketRxjsService } from '@mca/system/api';
import { Amplify } from 'aws-amplify';
import { initialConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { filter, first, of, switchMap } from 'rxjs';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { MenuService } from './app/layout/header/menu.service';
import { environment } from './environments/environment';
import { generatedServiceWorkerUrl } from './workers/service-worker-config';

if (environment.production) {
  enableProdMode();
}

// service workers only available with https
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(generatedServiceWorkerUrl, { type: 'module' }).then(async () => {
    const worker = await navigator.serviceWorker.ready;
    await worker.update();
    worker.active?.postMessage({ command: ServiceWorkerCommand.CLEAN_CACHE });
  });
}

Amplify.configure({
  Auth: { Cognito: environment.cognitoConfig },
});

export const initMenu = (menuService: MenuService, authService: AuthService) => () =>
  authService.tokenLoaded$.pipe(
    switchMap(awsToken => (awsToken ? menuService.loaded$.pipe(filter(Boolean)) : of(true))),
    first(),
  );

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      SharedFeatureSmartUiModule,
      ReactiveFormsModule,
      // initilize grid providers on top level
      SharedFeatureGridModule,
      MenubarModule,
      ToastModule,
    ),
    { provide: environmentToken, useValue: environment },
    MessageService,
    DecimalPipe,
    DialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: initMenu,
      deps: [MenuService, AuthService],
      multi: true,
    },
    { provide: WebsocketBridge, useClass: WebsocketRxjsService },
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
    provideEnvironmentNgxMask({
      thousandSeparator: ',',
      decimalMarker: '.',
      allowNegativeNumbers: true,
      specialCharacters: initialConfig.specialCharacters.filter(v => v !== '-'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      patterns: { ...initialConfig.patterns, N: { pattern: /[-0-9]/ } },
    }),
    provideAnimations(),
    provideRouter(
      APP_ROUTES,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      }),
      withComponentInputBinding(),
      // withDebugTracing(),
    ),
    provideHttpClient(withInterceptors([authInterceptor, errorsInterceptor])),
    {
      provide: serviceWorkerClientUrlToken,
      useValue: generatedServiceWorkerUrl,
    },
  ],
});
