import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@mca/auth/api';
import { HolidaysService } from '@mca/references/api';
import { BECommandService, ClientCommandService, NotificationsService } from '@mca/system/api';
import { UserLoginInfo } from '@mca/user/api';
import { appTitle } from '../environments/environment';
import { ToastModule } from 'primeng/toast';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { SharedFeatureSmartUiModule } from '@mca/shared/feature-smart-ui';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [HeaderComponent, SharedFeatureSmartUiModule, RouterOutlet, ToastModule],
})
export class AppComponent implements OnInit {
  private authService = inject(AuthService);
  private holidaysService = inject(HolidaysService);
  private notificationsService = inject(NotificationsService);
  private beCommandService = inject(BECommandService);
  private clientCommandService = inject(ClientCommandService);

  loading = this.holidaysService.loading;
  user = toSignal<UserLoginInfo | null>(this.authService.currentUser$);

  constructor(title: Title) {
    title.setTitle(appTitle);
  }

  ngOnInit() {
    this.authService.currentUserLoaded$.subscribe(() => {
      this.holidaysService.fetchHolidays();
    });
    this.notificationsService.watchMessages();
    this.beCommandService.watchMessages();
    this.clientCommandService.watchMessages();
  }
}
